<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
        <AccountInfo />
    </div>
    <DataserviceLogo />
    <NavMenuSecondary page="account" :data="this.$route.params.data" />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import AccountInfo from "../components/account/shelter/AccountInfo.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue"
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    SectionLeft,
    AccountInfo,
    DataserviceLogo,
    NavMenuSecondary
  },
};
</script>